import React from 'react';

const Home = React.lazy(() => import('./views/Home/Home'));
const SalesPolicy = React.lazy(() => import('./views/SalesPolicy/SalesPolicy'));
const ReturnPolicy = React.lazy(() => import('./views/ReturnPolicy/ReturnPolicy'));
const BoxingCharges = React.lazy(() => import('./views/BoxingCharges/BoxingCharges'));
const Logos = React.lazy(() => import('./views/Logos/Logos'));

const routes = [
  { path: '/', exact: true, name: 'Home', component: Home },
  { path: '/sales-policy', exact: true,  name: 'Sales Policy', component: SalesPolicy },
  { path: '/return-policy', exact: true,  name: 'Return Policy', component: ReturnPolicy },
  { path: '/boxing-charges', exact: true,  name: 'Boxing Charges', component: BoxingCharges },
  { path: '/logos', exact: true,  name: 'Logos', component: Logos },
  { path: '/catalogue', exact: true,  name: 'Categories', component: Home },
];

export default routes;
