import thunk from 'redux-thunk';
import rootReducer from './reducers';
import {createStore, applyMiddleware, compose} from 'redux';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = (initialState) => {
  const middleware = [thunk];
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware), f => f),
  );
}
export default store;