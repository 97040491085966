
const initialState = {
    sidebarShow: 'responsive'
}
const changeSideBarState = (state = initialState, action) => {
    switch (action.type) {
        case 'set':
            return { ...state, sidebarShow: action.payload }
        default:
            return state
    }
};
export default changeSideBarState;