import React, { useEffect, useRef, useState } from 'react'
import {
  TheContent,
  TheSidebar,
  // TheFooter,
  TheHeader
} from './index'
import '../scss/style.scss';

const TheLayout = () => {
  const [position, setPosition] = useState({ top: 0, left: 0 })
  useEffect(() => {
    window.scroll({
      top: position.top,
      left: position.left,
      behavior: 'smooth',
    })
  })

  const scrollTop = useRef();

  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <TheContent />
          <div
            className="toTop"
            ref={scrollTop}
            onClick={() => setPosition({ ...position, position: { top: 0, left: 0 } })} >
            ^ Back To Top
          </div>
        </div>
        {/* <TheFooter/> */}
      </div>
    </div>
  )
}

export default TheLayout
