import React, { useEffect, useState } from 'react'
import {
  CHeader,
  CToggler,
  CBreadcrumb,
  CBreadcrumbItem,
} from '@coreui/react'
import { sidebarChange } from 'src/actions';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';

//const url = process.env.API_URL;
//const url = 'https://latestrageapi.com'
const url = 'https://latestrageapi2.com'

const TheHeader = (props) => {
  const [breadCumbURL, setBreadCrumbURL] = useState([]);
  const paths = {
    '/': 'Home',
    '/priceList': 'Price List',
    '/sales-policy': 'Sales Policy',
    '/return-policy': "Return Policy",
    '/boxing-charges': 'Boxing Charges',
    '/logos': 'Logos'
  }

  const location = useLocation();

  useEffect(() => {
    let search = location.search?.split("categoryID=")[1]
    if(search){
      if(search.includes("&")){
        let cateId = search.split("&")[0]
        axios.get(`${url}/api/Category/GetCategoryBreadcrumById?id=${cateId}`).then((response) => {
          setBreadCrumbURL(response.data);
        }).catch((err) => console.log(err));
      }
      else{
        axios.get(`${url}/api/Category/GetCategoryBreadcrumById?id=${search}`).then((response) => {
          setBreadCrumbURL(response.data);
        }).catch((err) => console.log(err));
      }
    }
  }, [location])

  const sidebarShow = props.changeSideBarState.sidebarShow;
  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    props.dispatch(sidebarChange(val));
  }

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    props.dispatch(sidebarChange(val));
  }

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CBreadcrumb>
        {breadCumbURL.length === 0 || !location.search ? 
          <CBreadcrumbItem active>{paths[location.pathname]}</CBreadcrumbItem>
          :
          breadCumbURL.map((breadCumb, index) => {
            return <React.Fragment key={index}>
              {index !== breadCumbURL.length - 1 ?
                <CBreadcrumbItem key={index}>
                  <Link to={breadCumb.categoryId === 0 ? '/' : `/catalogue?categoryID=${breadCumb.categoryId}`}>
                    {breadCumb.categoryName}
                  </Link>
                </CBreadcrumbItem>
                : <CBreadcrumbItem active key={index}>{breadCumb.categoryName}</CBreadcrumbItem>}
            </React.Fragment>
          }
          )
        }
      </CBreadcrumb>
    </CHeader>
  )
}

const select = state => state;
export default connect(select)(TheHeader)
