const initialState = {};

const locationSet = (state = initialState, action) => {
  switch (action.type) {
    case 'pathname':
      return { ...state, pathName: action.payload };
    case 'searchStr':
      return { ...state, searchStr: action.payload };
    default:
      return state;
  }
};
export default locationSet;