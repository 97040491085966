
import { combineReducers } from 'redux';
import locationSet from './url';
import changeSideBarState from './sideBar';

const rootReducer = combineReducers({
  locationSet,
  changeSideBarState,
});

export default rootReducer;